import { AnimatePresence } from 'framer-motion';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Home, PageNotFound } from '../app/index';
import { RoutePattern } from './RoutePattern';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route exact path={RoutePattern.Home} component={Home} />
        <Route exact path="*" component={PageNotFound} />
      </Switch>
    </AnimatePresence>
  );
}
